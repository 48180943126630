import { DeleteAiQuestionModal, DELETE_AI_QUIZ_MODAL } from "./DeleteAiQuestion";
import { UPDATE_AI_QUESTION, UpdateAiQuestionModal } from "./UpdateAiQuestion";

export const AiQuestionModals = () => {
    return (
        <>
            <DeleteAiQuestionModal id={DELETE_AI_QUIZ_MODAL} />
            <UpdateAiQuestionModal id={UPDATE_AI_QUESTION} />
        </>
    );
};