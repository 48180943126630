import { joiResolver } from "@hookform/resolvers/joi";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/atoms/Button";
import {
    throwErrorToast,
    throwSuccessToast
} from "../../../../../ui/organisms/Toaster";
import { useModal } from "../../../../modalpage/hooks";
import { AiQuestion, Languages } from "../../../../types";
import { LanguageTabChanger } from "../../../../../ui/organisms/LanguageTabChanger";
import { FormTextareaProps } from "../../../../../ui/atoms/FormTextarea";
import { FormInput } from "../../../../../ui/atoms/FormInput";
import { AiAnswers } from "../../organizm/AiQuestions";
import { langTabs } from "../../../../../ui/organisms/LanguageTabChanger";
import { Checkbox } from "../../atoms/Checkbox";
import { AiQuestionPayload } from "../../../types";
import { transformAiQuestion, transformPayload } from "../../../utils/transformAiQuestion";
import { aiQuestionStore } from "../../../store/AiQuestionStore";

interface UpdateAiQuestionModalProps {
    id: string;
}

export const UPDATE_AI_QUESTION = "UPDATE_AI_QUESTION";

export const UpdateAiQuestionModal: FC<UpdateAiQuestionModalProps> = observer(
    ({ id }) => {
        const { t } = useTranslation();
        const [isFieldsLoading, setIsFieldsLoading] = useState(false);
        const {
            register,
            activeModalId,
            closeModal,
            modalData,
            modalCallback
        } = useModal<{
            item: AiQuestion;
        }>();

        const {
            updateOrCreateAiQuestion
        } = useContext(aiQuestionStore);

        useEffect(() => {
            register({
                id
            });
        }, [id, register]);

        const formMethods = useForm({
            mode: "onSubmit",
            reValidateMode: "onChange",
            defaultValues: {
                currentLang: { ...langTabs.find(lang => lang.value === "en") }
            }
        });
        const { reset, handleSubmit, control } = formMethods;

        const language = useWatch({ name: "currentLang", control });

        useEffect(() => {
            if (id === activeModalId && modalData?.item) {
                const updatedData = transformAiQuestion(modalData.item);
                reset({
                    ...updatedData,
                    currentLang: language
                });
            }
            else {
                reset({ currentLang: language });
            }
        }, [reset, activeModalId, modalData, id]);

        const [isLoading, setLoading] = useState<boolean>(false);

        const TextInputWithLang = useCallback(
            ({ name, label, placeholder }: Partial<FormTextareaProps>) => {
                const langValue = language?.value || 'en';
                const textareaName = `${name}.${langValue}`;
                return (
                    <FormInput
                        name={textareaName}
                        className="col-span-2"
                        label={label}
                        maxLength={600}
                        placeholder={placeholder}
                        isDisabled={isFieldsLoading}
                    />
                );
            },
            [language?.value, t, isFieldsLoading]
        );

        const onSubmit = async (data: AiQuestionPayload) => {
            setLoading(true);
            const updatedData = transformPayload(data);
            try {
                const response = await updateOrCreateAiQuestion(updatedData);
                if (response) {
                    setLoading(false);
                    closeModal();
                    throwSuccessToast(
                        (
                            modalData?.item._id
                                ? t("ai.questions.updated")
                                : t("ai.questions.added")
                        )
                    );
                    const cb = modalCallback[UPDATE_AI_QUESTION];
                    cb && cb(true);
                }
            } catch (error) {
                throwErrorToast(t("error"), t("unknownError"));
            } finally {
                setLoading(false);
            }
        };

        if (activeModalId !== id) return null;
        return (
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <section className="w-1200p bg-white rounded-20p flex flex-col text-dark-main p-6">
                        <h4 className="text-18 font-semibold mb-6">
                            {modalData
                                ? t("ai.questions.edit")
                                : t("ai.questions.add")}
                        </h4>
                        <div className="grid grid gap-4 mb-4">
                            <LanguageTabChanger
                                name="currentLang"
                                fields={["question", "answers"]}
                                onLoadingChange={setIsFieldsLoading}
                            />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
                            <div className="flex items-center mb-2">
                                <h5 className="text-16 font-medium mr-20">{t("question")}</h5>
                                <Checkbox
                                    name={'isFreeAnswer'}
                                >
                                    <p className="text-14 text-dark-text ml-4">{t("ai.questions.isFreeAnswer")}</p>
                                </Checkbox>
                                <Checkbox
                                    name={'isSliderAllowed'}
                                    className="ml-20"
                                >
                                    <p className="text-14 text-dark-text ml-4">{t("ai.questions.isSliderAllowed")}</p>
                                </Checkbox>
                            </div>
                            <TextInputWithLang
                                className="mt-2 break-words"
                                hideLabel={true}
                                name="question"
                                placeholder={t("ai.questions.typeQuestion")}
                                textareaClasses="resize-none h-120p p-2 border rounded-md border-gray-300"
                            />
                            <AiAnswers
                                name='answers'
                                lang={language.value as Languages}
                                isLoading={isFieldsLoading}
                                key={language.value}
                            />
                        </div>
                        <div className="w-full p-6 flex rounded-b-20p">
                            <Button
                                text={modalData ? t("save") : t("add")}
                                isDisabled={isLoading}
                                className="mr-4"
                            />
                            <Button
                                text={t("cancel_2")}
                                type="tertiary"
                                htmlType="button"
                                onClick={closeModal}
                                isDisabled={isLoading}
                            />
                        </div>
                    </section>
                </form>
            </FormProvider>
        );
    }
);

