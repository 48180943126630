import { SUPPORTED_LANGUAGES } from "../utils/i18n";
import reduceFields, { reduceList } from "../utils/reduceList";

const aiSetting = [
    "apiKey",
    "version",
    "textPrompt",
    "maxToken",
    "wineImagePrompt",
    "menuImagePrompt",
];

const aiQuestion = [
    "isFreeAnswer",
    "isSliderAllowed",
    "replyCount",
    "answers",
    { question: SUPPORTED_LANGUAGES },
    { answers: SUPPORTED_LANGUAGES },

];

export const aiSettingFields = reduceList(aiSetting);

export const aiQuestionFields = reduceList(aiQuestion);
export const aiQuestionListFields = reduceFields({
    items: aiQuestion
});