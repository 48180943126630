import { get, put, post, remove } from "../axiosConfig";
import { MobileVersion, ListResponse, AiSettings } from "../../../types";
import axios, { Canceler } from "axios";
import { mobileVersionFields, mobileVersionListFields } from "../../../fields/mobileVersion";
import { aiSettingFields } from "../../../fields/ai";

const CancelToken = axios.CancelToken;
let cancel: Canceler | undefined;


export const SettingsAPI = {
    async getMobileVersionList({
        skip,
        search,
        limit,
        sort
    }: {
        skip?: number;
        limit?: number;
        search?: string;
        sort?: string;
    }): Promise<ListResponse<MobileVersion>> {
        if (cancel) {
            cancel();
        }
        const params = {
            q: search,
            skip,
            limit,
            sort,
            _fields: mobileVersionListFields
        };
        const { data } = await get(`/api/v1/admin/mobile-version`, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            }),
            params
        });
        return data.result;
    },

    async updateMobileVersion({
        id,
        formData
    }: {
        id: string;
        formData: Partial<MobileVersion>;
    }): Promise<MobileVersion> {
        const params = {
            _fields: mobileVersionFields
        };
        const { data } = await put(
            `/api/v1/admin/mobile-version/${id}`,
            formData,
            {
                params
            }
        );
        return data.result;
    },

    async createMobileVersion(formData: Partial<MobileVersion>): Promise<MobileVersion> {
        const params = {
            _fields: mobileVersionFields
        };
        const { data } = await post(
            `/api/v1/admin/mobile-version`,
            formData,
            {
                params
            }
        );
        return data.result;
    },

    async deleteMobileVersion(id: string): Promise<MobileVersion> {
        const { data } = await remove(
            `/api/v1/admin/mobile-version/${id}`,
        );
        return data.result;
    },

    async getAiSettings(): Promise<AiSettings | null> {
        const params = {
            _fields: aiSettingFields
        };
        const { data } = await get('/api/v1/admin/ai-sommelier-settings',
            {
                params
            });
        return data.result;
      },
    
      async createAiSettings(settings: AiSettings): Promise<AiSettings> {
        const { data } = await post('/api/v1/admin/ai-sommelier-settings', settings);
        return data.result;
      },
    
      async updateAiSettings(settings: AiSettings, id: string): Promise<AiSettings> {
        const { data } = await put(`/api/v1/admin/ai-sommelier-settings/${id}`, settings);
        console.log("data",data);
        return data.result;
      },
};
