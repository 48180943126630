import reduceFields, { reduceList } from "../utils/reduceList";

const manufacturer = [
    "name",
    {logo: ["url"]},
    {country: ["name"]},
    "countryId",
    {article: ["createDate"]},
    "balances",
    "weight",
    {photos: ["url"]},
    {videos: ["url"]},
    {coordinates: ["latitude", "longitude"]},
    "description",
    "photosId",
    "videosId",
    "responderName",
    {avatar: ["url"]},
    "prompt",

];

export const manufacturerFields = reduceList(manufacturer);

export const manufacturerListFields = reduceFields({
    items: manufacturer
});