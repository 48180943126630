import {
    UpdateMobileVersionModal,
    UPDATE_MOBILE_VERSION
} from "./UpdateMobileVersion";
import {
    RemoveMobileVersionModal,
    REMOVE_MOBILE_VERSION,
} from "./RemoveMobileVersion";
import {
    UpdateAiSettingsModal,
    UPDATE_AI_SETTINGS,
} from "./AiSettings";

export const  MobileVersionModals = () => {
    return (
        <>
            <UpdateMobileVersionModal id={UPDATE_MOBILE_VERSION} />
            <RemoveMobileVersionModal id={REMOVE_MOBILE_VERSION} />
            <UpdateAiSettingsModal id={UPDATE_AI_SETTINGS} />
        </>
    );
};
