import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { useController } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { TabOptions, TernaryTabList } from "../TernaryTabList";
import { Button } from '../../../ui/atoms/Button';
import { Spinner } from "../../atoms/Spinner";
import { AiAPI } from '../../../features/utils/api/requests/ai-requests';
interface LanguageChangerProps {
    name: string;
    fields?: string[];
    onLoadingChange?: (isLoading: boolean) => void;
}

export const langTabs: TabOptions[] = [
    {
        label: "English",
        Icon: "🇬🇧",
        value: "en"
    },
    {
        label: "Russian",
        Icon: "🇷🇺",
        value: "ru"
    },
    {
        label: "Italian",
        Icon: "🇮🇹",
        value: "it"
    },
    {
        label: "French",
        Icon: "🇫🇷",
        value: "fr"
    },
    {
        label: "German",
        Icon: "🇩🇪",
        value: "de"
    },
    {
        label: "Spanish",
        Icon: "🇪🇸",
        value: "es"
    }
];

export const LanguageTabChanger: FC<LanguageChangerProps> = observer(
    ({ name, fields, onLoadingChange }) => {
        const { control, getValues, setValue } = useFormContext();
        const [isLoading, setIsLoading] = useState(false);
        const {
            field: { value, onChange }
        } = useController({ name, control });

        const handleAITranslation = async () => {
            setIsLoading(true);
            if (onLoadingChange) onLoadingChange(true);

            if (fields && fields.length > 0) {
                const translationPromises = fields.map(async (field) => {

                    const { maxIndexCount, hasIndexes } = createMissingIndexesForLanguages(field, langTabs, getValues, setValue);
                    const getFieldValues = (index?: number) => langTabs.reduce((acc, lang) => {
                        const fieldPath = index !== undefined ? `${field}.${lang.value}.${index}.text` : `${field}.${lang.value}`;
                        acc[lang.value] = getValues(fieldPath);
                        return acc;
                    }, {} as Record<string, string>);

                    try {
                        if (hasIndexes) {
                            const indexedTranslationPromises = Array.from({ length: maxIndexCount }, (_, i) => (async () => {
                                const values = getFieldValues(i);
                                const translations = await AiAPI.translateFields(langTabs.map(lang => lang.value), values);

                                Object.entries(translations).forEach(([lang, text]) => {
                                    setValue(`${field}.${lang}.${i}.text`, text);
                                });
                            })());

                            await Promise.all(indexedTranslationPromises);
                        } else {
                            const values = getFieldValues();
                            const translations = await AiAPI.translateFields(langTabs.map(lang => lang.value), values);

                            Object.entries(translations).forEach(([lang, text]) => {
                                setValue(`${field}.${lang}`, text);
                            });
                        }
                    } catch (error) {
                        console.error(`Error translating ${field}:`, error);
                    }
                });

                await Promise.all(translationPromises);
            }

            setIsLoading(false);
            if (onLoadingChange) onLoadingChange(false);
        };

        const createMissingIndexesForLanguages = (
            field: string,
            langTabs: TabOptions[],
            getValues: any,
            setValue: any
        ) => {
            const { langCount, hasIndexes } = countFieldIndexes(field, langTabs, getValues);
            if (!hasIndexes) return { maxIndexCount: 0, hasIndexes };

            const maxIndexCount = Math.max(...Object.values(langCount));

            langTabs.forEach(lang => {
                const currentCount = langCount[lang.value];

                if (currentCount < maxIndexCount) {
                    for (let i = currentCount; i < maxIndexCount; i++) {
                        const newField = `${field}.${lang.value}.${i}.text`;
                        setValue(newField, "");
                    }
                }
            });

            return { maxIndexCount, hasIndexes };
        };

        const countFieldIndexes = (field: string, langTabs: TabOptions[], getValues: any) => {
            const langCount = langTabs.reduce((acc, lang) => {
                acc[lang.value] = 0;
                return acc;
            }, {} as Record<string, number>);
            langTabs.forEach(lang => {
                let index = 0;
                while (true) {
                    const answerField = `${field}.${lang.value}.${index}.text`;
                    const answerValue = getValues(answerField);
                    if (answerValue !== undefined && answerValue !== null) {
                        langCount[lang.value]++;
                        index++;
                    } else {
                        break;
                    }
                }
            });

            const hasIndexes = Object.values(langCount).some(count => count > 0);
            return { langCount, hasIndexes };
        };

        return (
            <div>
                <TernaryTabList
                    value={value}
                    onChange={onChange}
                    options={langTabs}
                />
                {fields && fields.length > 0 && (
                    <Button
                        htmlType="button"
                        text={isLoading ? "Translating..." : "AI Translate"}
                        onClick={handleAITranslation}
                        className="mt-5"
                        isDisabled={isLoading}
                    >
                        {isLoading && <Spinner />}
                    </Button>
                )}
            </div>
        );
    }
);
