import { langTabs } from "../../../ui/organisms/LanguageTabChanger";
import { AiQuestion } from "../../types";
import { AiQuestionPayload } from "../types";

const languages = langTabs.map(lang => lang.value);

export const transformAiQuestion = (payload: AiQuestionPayload): AiQuestion => {
    const transformedAnswers: any = {};

    languages.forEach(lang => {
        transformedAnswers[lang] = (payload.answers?.[lang] || []).map(answer => ({
            text: answer,
        }));
    });

    return {
        ...payload,
        name: "",
        answers: transformedAnswers,
    };
};

export const transformPayload = (values: Partial<AiQuestionPayload>): Partial<AiQuestion> => {
    const transformedAnswers: Record<string, string[]> = {};

    languages.forEach(lang => {
        transformedAnswers[lang] = transformedAnswers[lang] || [];
        transformedAnswers[lang].push(...(values.answers?.[lang] || []).map((answer: any) => answer.text));
    });

    return {
        ...values,
        answers: transformedAnswers,
    };
};