import axios, { Canceler } from "axios";
import {
    AiQuestion,
    ListResponse
} from "../../../types";
import { get, post, put, remove } from "../axiosConfig";
import { aiQuestionFields, aiQuestionListFields } from "../../../fields/ai";

const CancelToken = axios.CancelToken;
let cancel: Canceler | undefined;

export const AiAPI = {
    async translateFields(languages: string[], values: Record<string, string>): Promise<Record<string, string>> {
        const { data } = await post('/api/v1/admin/translate', {
            languages,
            values
        });
        return data;
    },

    async getAiQuestionList(formData?: {
        skip?: number;
        limit?: number;
        search?: string;
        sort?: string;
        countryId?: string;
        disableCancel?: boolean;
    }): Promise<ListResponse<AiQuestion>> {

        const params = {
            q: formData?.search,
            skip: formData?.skip,
            limit: formData?.limit,
            sort: formData?.sort,
            _fields: aiQuestionListFields
        };
        if (cancel && !formData?.disableCancel) {
            cancel();
        }
        const { data } = await get("/api/v1/admin/ai-sommelier/questions", {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            }),
            params
        });

        return data.result;
    },

    async getAiQuestion(aiQuestionId: string, disableCancel = false): Promise<AiQuestion> {
        try {
            if (cancel && !disableCancel) {
                cancel();
            }
            const { data } = await get(`/api/v1/admin/ai-sommelier/questions/${aiQuestionId}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                }),
                params: {
                    _fields: aiQuestionFields
                }
            });

            return data.result;
        } catch (error) {
            console.error('Error updating:', error);
            throw error;
        }
    },

    async createAiQuestion({
        formData
    }: {
        formData: Partial<AiQuestion>;
    }): Promise<AiQuestion> {
        const { data } = await post(`/api/v1/admin/ai-sommelier/questions`, formData);

        return data.result;
    },
    async updateAiQuestion({
        id,
        formData
    }: {
        id: string;
        formData: Partial<AiQuestion>;
    }): Promise<AiQuestion> {
        const params = {
            _fields: aiQuestionFields
        };
        const { data } = await put(`/api/v1/admin/ai-sommelier/questions/${id}`, formData, {
            params
        });
        return data.result;
    },
    async removeAiQuestion(params: { id: string }): Promise<AiQuestion> {
        const { id } = params;

        try {
            const { data } = await remove(`/api/v1/admin/ai-sommelier/questions/${id}`);

            return data.result;
        } catch (error) {
            console.error('Error remove:', error);
            throw error;
        }
    },
};