import Joi from 'joi';
import { AiSettings } from '../../types';
import { TFunction } from "i18next";

export const getAiSettingSchema = (t: TFunction) =>
    Joi.object<AiSettings>({
        apiKey: Joi.string().required(),
        version: Joi.string().required(),
        textPrompt: Joi.string().required(),
        maxToken: Joi.number().required(),
        wineImagePrompt: Joi.string().required(),
        menuImagePrompt: Joi.string().required()
    }).unknown(true);