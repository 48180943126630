import { AnimatePresence, motion } from "framer-motion";
import { FC, useEffect, useState } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { FormInput } from "../../../../ui/atoms/FormInput";
import { TrashIcon } from "../../../../ui/atoms/Icon";
import { Languages } from "../../../types";

interface AiAnswersProps {
    name: string;
    lang: Languages;
    isLoading?: boolean;
}

export const AiAnswers: FC<AiAnswersProps> = ({
    name,
    lang,
    isLoading
}) => {
    const { t } = useTranslation();
    const { control, getValues, setValue } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        name: `${name}.${lang}`,
        control
    });

    useEffect(() => {
        const currentAnswers = getValues(`answers.${lang}`);
        setValue(`answers.${lang}`, currentAnswers);
    }, [lang, setValue, getValues]);

    const addNewOneAnswer = () => {
        append({ text: "" });
    };

    const handleRemoveAnswer = (index: number) => {
        remove(index);
    };

    const itemClasses = cn(
        "flex items-center relative pr-10 last:mb-0"
    );

    return (
        <div className="mt-5">
            {fields.length > 0 && (
                <ul className="grid grid-cols-2 gap-4">
                    <AnimatePresence>
                        {fields?.map((field, answerIndex) => (
                            <motion.li
                                key={field.id}
                                initial={{
                                    opacity: 0,
                                    y: -50,
                                    scale: 0.3
                                }}
                                animate={{
                                    opacity: 1,
                                    y: 0,
                                    scale: 1
                                }}
                                className={itemClasses}
                            >
                                <div className="flex-1 w-full">
                                    <FormInput
                                        name={`${name}.${lang}.${answerIndex}.text`}
                                        hideLabel={true}
                                        isDisabled={isLoading}
                                        className="break-words"
                                    />
                                </div>
                                {!isLoading && (
                                    <TrashIcon
                                        className="cursor-pointer m-2 absolute right-0 top-0 bottom-0"
                                        onClick={() =>
                                            handleRemoveAnswer(answerIndex)
                                        }
                                    />
                                )}
                            </motion.li>
                        ))}
                    </AnimatePresence>
                </ul>
            )}
            {!isLoading && (
                <div className="mt-4">
                    <button
                        className="outline-none text-14 text-purple-main font-semibold transition-all"
                        onClick={addNewOneAnswer}
                        type="button"
                    >
                        {`+ ${t(`new`, {
                            word: t("answer").toLowerCase()
                        })}`}
                    </button>
                </div>
            )}
        </div>
    );
};

