import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import { AiAPI } from "../../utils/api/requests/ai-requests";
import { AiQuestion } from "../../types";

class AiQuestionStore {
    search: string = "";
    isEditing: boolean = false;
    isLoading: boolean = false;
    isError: boolean = false;
    errorDetails: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    handleSearch = (value: string) => {
        this.search = value;
    };

    setLoading = (bool: boolean) => {
        this.isLoading = bool;
    }

    updateOrCreateAiQuestion = async (formData: Partial<AiQuestion>) => {
        let data;
        if (formData._id) {
            data = await AiAPI.updateAiQuestion({
                id: formData._id,
                formData
            });
        } else {
            data = await AiAPI.createAiQuestion({
                formData
            });
        }
        return data;
    };

    clear = () => {
        this.isLoading = false;
        this.isError = false;
        this.errorDetails = null;
        this.isEditing = false;
    };

    setEditing = (status: boolean) => {
        this.isEditing = status;
    };
}

export const aiQuestionStore = createContext(new AiQuestionStore());
